@import "./var.scss";

.daily-specials {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1024px) {
    margin-top: 40px;
  }
  .headingBox {
    background: $darkRed;
    padding: 5px 40px;
    border-radius: 60px;
    margin-bottom: 10px;
    h1 {
      color: #fff;
      line-height: 1;
      font-family: $fontKalam;
      font-size: 24px;
      @media (min-width: 399px) {
        font-size: 30px;
      }
      @media (min-width: 1024px) {
        font-size: 44px;
      }
    }
  }
  .titleDesc {
    text-align: center;
    color: lighten($darkNavy, 10%);
    font-weight: bold;
    font-style: italic;
    line-height: 1.2;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .seoulContainer {
    overflow: hidden;
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    .left-outer {
      position: relative;
      width: 100%;
      height: 100%;
      .fade {
        opacity: 0;
        z-index: 1;
      }
      .fade-enter {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        opacity: 1;
        // transition: opacity 300ms linear 150ms;
        transition: opacity 300ms linear;
      }

      .fade-enter.fade-enter-active {
        opacity: 1;
      }
      .fade-exit {
        opacity: 1;
      }
      .fade-exit.fade-exit-active {
        opacity: 0;
        transition: opacity 150ms linear;
      }
      .fade-enter-done {
        opacity: 1;
      }
    }
    .left {
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // height: 280px;
      height: 320px;
      width: 100%;
      .content {
        left: 0;
        top: 0;
        right: 0;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-left: 10px;
        padding-right: 10px;
        p {
          flex: 1 0 auto;
          display: flex;
          align-items: center;
          color: #fff;
          text-align: center;
          font-size: 20px;
          text-shadow: 0 0 4px rgba(0, 0, 0, 0.72), 0 0 14px rgba(0, 0, 0, 0.45);
        }
        .left-day {
          transform: translateY(-100%);
          transition: transform 500ms;
          font-style: italic;
        }
        .left-name {
          font-family: $fontAmatic;
          font-weight: 700;
          font-size: 44px;
          opacity: 0;
          transition: opacity 1000ms;
          display: flex;
          justify-content: center;
          width: 100%;
          // max-width: 280px;
          // max-width: 300px;
          position: relative;
          // .left-desc2 {
          //   position: absolute;
          //   font-family: $fontRobotoSlab;
          //   left: 50%;
          //   transform: translateX(-50%);
          //   top: 81%;
          //   font-size: 12px;
          //   width: 100%;
          // }
          .left-desc2 {
            position: absolute;
            font-family: $fontRobotoSlab;
            left: 50%;
            transform: translateX(-50%);
            top: 90%;
            font-size: 12px;
            width: 100%;
          }
          .priceBox {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 65%;
            font-size: 16px;
            width: 100%;
            .regularPrice {
              font-family: $fontRobotoSlab;
              text-decoration: line-through;
              font-size: 18px;
            }
            .price {
              margin-left: 5px;
              font-family: $fontPlayfair;
              color: lighten($lightBlue, 15%);
              // color: $darkNavy;
              font-size: 26px;
            }
          }
        }
        .left-desc {
          transform: translateY(100%);
          transition: transform 500ms;
          font-style: italic;
          line-height: 1;
        }
      }
      &.fade-enter-done {
        .left-day,
        .left-desc {
          transform: translateY(0);
        }
        .left-name {
          opacity: 1;
          font-size: 44px;
        }
      }
    }
    .right {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .right-inner {
        display: flex;
        flex-wrap: wrap;
        padding: 5px;
        width: 100%;
        .right-item {
          // flex-basis: 33.33%;
          // width: calc(33.33% - 10px);
          width: 33.33%;
          padding: 4px;
          backface-visibility: hidden;
          &.active {
            .right-item-inner:before {
              opacity: 0;
            }
            .right-item-inner {
              .content {
                background: rgba(0, 0, 0, 0.3);
              }
            }
          }

          .right-item-inner {
            width: 100%;
            height: 60px;
            position: relative;
            cursor: pointer;
            overflow: hidden;
            backface-visibility: hidden;
            border-radius: 5px;
            box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.2);
            &:before {
              position: absolute;
              backface-visibility: hidden;
              content: "";
              top: 0;
              left: 0;
              right: 0;
              height: 100%;
              background: linear-gradient(
                to right,
                rgba(0, 0, 0, 0.7),
                rgba(0, 0, 0, 0.3)
              );
              transition: opacity 500ms ease-in-out;
              opacity: 1;
            }
            .content {
              color: #fff;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              padding: 2px 6px;
              border-radius: 3px;
              text-shadow: 0 0 4px rgba(0, 0, 0, 0.72),
                0 0 14px rgba(0, 0, 0, 0.45);
              transition: background 500ms ease-in-out;

              h5 {
                font-style: italic;
              }
              .everyday {
                color: lighten($lightBlue, 30%);
              }
            }
          }
        }
      }
    }
    @media (min-width: 1024px) {
      height: 100%;
      height: 500px;
      flex-direction: row;
      margin-top: 20px;
      .left-outer {
        flex-basis: 50%;
      }
      .left,
      .right {
        flex-basis: 50%;
        height: 100%;
      }
      .left {
        border-radius: 40px;
        order: 1;
        height: 500px;
      }
      .right {
        order: 2;
        height: auto;
        justify-content: center;
        align-items: center;

        .right-inner {
          padding: 10px;
          .right-item {
            padding: 10px;
            &.active {
              .right-item-inner:before {
                opacity: 0;
              }
              .right-item-inner {
                .content {
                  background: rgba(0, 0, 0, 0.3);
                }
              }
            }
            .right-item-inner {
              border-radius: 20px;
              height: 200px;
              &:before {
                background: linear-gradient(
                  to right,
                  rgba(0, 0, 0, 0.7),
                  rgba(0, 0, 0, 0.3)
                );
              }
            }
          }
        }
      }
    }
  }
}
