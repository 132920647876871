@import './var.scss';

.toTop {
  z-index: 3;
  position: fixed;
  right: -10%;
  bottom: -10%;
  transition: all 500ms;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  // background: rgba(35, 45, 62, 0.7);
  background: $darkRed;
  color: #fff;
  opacity: 0.5;
  border: 1px solid #fff;
  cursor: pointer;
  transition: all 300ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  &:hover {
    // background: rgba(35, 45, 62, 1);
    // color: rgba(255, 255, 255, 1);
    opacity: 1;
  }
  &:active {
    transform: scale(0.8);
  }
  .fa-chevron-up {
    // color: #fff;
    color: #fff;
  }
  &.active {
    right: 2%;
    bottom: 2%;
  }
}
