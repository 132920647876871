@import './var';

.header {
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  position: relative;
  height: 400px;
  // background-size: 100% 400px;
  background-size: 100% 100%;
  @media (min-height: 1200px) {
    height: 600px;
    // background-size: 100% 600px;
    background-size: 100% 600px;
  }
  @media (max-width: 767px) {
    height: 260px;
    // background-size: 100% 260px;
    background-size: 100% 260px;
    // background-position: 150% 260px;
  }
  h2 {
    position: absolute;
    font-family: $fontAmatic;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: $offWhite;
    line-height: 1.3;
    word-spacing: 5px;
    text-align: center;
    width: 80%;
    max-width: 640px;
  }
}
.menuHeader {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)),
    url('/images/menu/kimchi-backgroundDark.jpg');
  @media (min-height: 1200px) {
    background-size: 100% 600px;
    // background-size: 100% 100%;
  }
  @media (max-width: 767px) {
    background-size: 100% 100%;
  }
}

.cateringHeader {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)),
    url('/images/menu/kimchi-backgroundDark.jpg');
  @media (min-height: 1200px) {
    background-size: 100% 600px;
    // background-size: 100% 100%;
  }
  @media (max-width: 767px) {
    background-size: 100% 100%;
  }
}

.contactHeader {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)),
    url('/images/contact-header.jpg');
  background-position: bottom center;
  @media (min-height: 1200px) {
    background-size: 100% 600px;
  }
  @media (max-width: 767px) and (orientation: portrait) {
    background-size: 100% 260px;
  }
}
