// @import './global';
@import "./var";

.fullWidthContainer {
  width: 100%;
  background: url("/images/menu-outer-bg3.jpg");
  background-repeat: repeat;
  // background: linear-gradient(to bottom right, #b24592, #f15f79);
  padding: 1rem 0 1rem 0;
}

.seoulContainer {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
  position: relative;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1460px) {
    max-width: 1430px;
  }
}

.seoulContainer-m {
  width: 100%;
  margin: 0 auto;
  // padding: 4px;
  overflow-x: hidden;
  @media (min-width: 576px) {
    max-width: 540px;
    // padding: 10px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
    // padding: 20px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
}

.dailySpecialContainer {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
  position: relative;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  // @media (min-width: 1460px) {
  //   max-width: 1430px;
  // }
}
