@import "./var";
// .outer {
//   background: linear-gradient(to bottom right, #b24592, #f15f79);
// }
.info-box {
  // margin-top: 10px;
  // margin-bottom: 10px;
  // margin-top: 40px;
  margin-top: 30px;
  // margin-bottom: 40px;
  width: 100%;
  background: url("/images/menu-outer-bg3.jpg");
  background-repeat: repeat;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // flex-direction: column;
  @media (max-width: 1024px) and (orientation: portrait) {
    // margin-top: 30px;
    // margin-bottom: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    margin-top: 0;
    margin-bottom: 0;
  }
  h2 {
    text-align: center;
    line-height: 1.3;
    color: $offWhite;
    background: $darkNavy;
    display: inline-block;
    // height: 60px;
    padding: 10px 15px;
    border-radius: 10px;
    // font-size: 20px;
    font-family: $fontAmatic;
    font-size: 26px;
    letter-spacing: 1px;
    margin-top: 10px;
  }
  .info-items {
    // padding: 0 120px;
    width: 100%;
    margin-top: 20px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
    }
    .store-hours {
      border-right: 2px solid #b2b2b2;
      @media (max-width: 1023px) {
        border: 0;
      }
    }
    .store-hours,
    .store-info {
      display: flex;
      flex-direction: column;
      h3 {
        text-align: center;
        color: $black;
        text-transform: uppercase;
        // font-family: $fontLeckerli;
        font-family: $fontPlayfair;
        font-size: 30px;
        line-height: 1;
        margin-top: 10px;
      }
      .store-hours-items,
      .store-info-items {
        width: 100%;
        .store-hours-item {
          .item-1 {
            font-family: $fontOpenSans;
          }
          .item-2 {
            font-family: $fontPlayfair;
          }
        }
        .store-hours-item,
        .store-info-item {
          width: 100%;
          display: flex;
          margin-top: 10px;
          .item {
            flex-basis: 50%;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 18px;

            @media (max-width: 576px) {
              font-size: 14px;
            }
            @media (max-width: 359px) {
              font-size: 12px;
            }
          }
          .item-1 {
            text-align: right;
            padding-right: 20px;
            color: $black;

            @media (max-width: 576px) {
              padding-right: 10px;
            }
          }
          .item-2 {
            text-align: left;
            padding-left: 20px;
            @media (max-width: 576px) {
              padding-left: 10px;
            }
          }
          .green {
            color: $green;
          }
          .darkRed {
            color: $darkRed;
          }
        }
      }
    }

    .store-info {
      h3 {
        margin-top: 20px;
      }
      .store-info-items {
        width: 80% !important;
        margin: 0 auto;

        .store-info-item {
          .item-1 {
            flex-basis: 20% !important;
          }
          .item-2 {
            flex-basis: 80% !important;
          }
          margin-top: 20px !important;
          .fa-map-marked-alt,
          .fa-phone {
            color: $orange;
            font-size: 30px;
          }
          p {
            font-family: $fontOpenSans;
            font-size: 18px;
            color: $black;
            line-height: 1.4;
            display: flex;
            align-items: flex-start;
            font-weight: bold;
          }
        }
      }
    }

    .store-logo {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        max-width: 240px;
        backface-visibility: hidden;
      }
    }

    @media (max-width: 1023px) {
      .store-hours {
        order: 1;
      }
      .store-info {
        order: 2;
      }
      // .store-logo {
      //   order: 3;
      // }
    }
    // .fa-map-marked-alt,
    // .fa-phone {
    //   font-size: 20px;
    // }
  }
}

// .map-box {
//   background: $darkNavy;
//   margin-top: 30px;
//   margin-bottom: 30px;
//   .buttonBox {
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
//     padding: 5px;
//     padding-right: 3px;
//     a {
//       padding: 5px 10px;
//       color: $offWhite;
//       margin-right: 2px;
//       border: 1px solid $offWhite;
//       cursor: pointer;
//     }
//   }
//   @media (max-width: 1024px) and (orientation: portrait) {
//     margin-top: 20px;
//     margin-bottom: 20px;
//     padding: 0;
//   }
//   @media (max-width: 767px) and (orientation: portrait) {
//     margin-top: 0;
//     margin-bottom: 0;
//     padding: 0;
//   }
//   .store-map {
//     width: 100%;
//     height: 500px;
//     @media (max-width: 767px) and (orientation: portrait) {
//       height: 400px;
//     }
//     @media (max-height: 414px) and (orientation: landscape) {
//       height: 300px;
//     }
//     @media (max-height: 380px) and (orientation: landscape) {
//       height: 280px;
//     }
//   }
// }
.map-box {
  background: $mediumGrey;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 16px 0;
  border: 1px solid $darkNavy;
  .buttonBox {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    padding-right: 3px;
    background: transparent;
    a {
      background: $darkNavy;
      // padding: 5px 10px;
      padding: 16px;
      color: $offWhite;
      margin-right: 2px;
      border: 1px solid $offWhite;
      cursor: pointer;
      text-align: center;
      font-size: 20px;
      line-height: 1.5;
    }
  }
  @media (max-width: 1024px) and (orientation: portrait) {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
  }
  @media (max-width: 767px) and (orientation: portrait) {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .store-map {
    width: 100%;
    height: 500px;
    @media (max-width: 767px) and (orientation: portrait) {
      height: 400px;
    }
    @media (max-height: 414px) and (orientation: landscape) {
      height: 300px;
    }
    @media (max-height: 380px) and (orientation: landscape) {
      height: 280px;
    }
  }
}
