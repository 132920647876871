@import "./var";

.menu-container {
  background: #fff;
  overflow: hidden;
  padding: 10px 0;
  @media (min-width: 767px) {
    border-radius: 5px;
  }
  @media (max-width: 359px) {
    padding: 5px 0;
  }
  .menuRouteAnimation {
    position: relative;
    .fade {
      opacity: 0;
      z-index: 1;
    }
    .fade-enter {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      opacity: 1;
      transition: opacity 300ms linear 150ms;
    }

    .fade-enter.fade-enter-active {
      opacity: 1;
    }
    .fade-exit {
      opacity: 1;
    }
    .fade-exit.fade-exit-active {
      opacity: 0;
      transition: opacity 150ms linear;
    }
  }

  .menuContentBox {
    backface-visibility: hidden;
    position: relative;
    h1 {
      color: $darkNavy;
      text-align: center;
      font-family: $fontAmatic;
      font-size: 64px;
      font-weight: 700;
      line-height: 1.4;
      @media (max-width: 767px) {
        font-size: 44px;
      }
    }
    .cashOnly {
      font-size: 12px;
      position: absolute;
      top: -0.2%;
      right: 1%;
      font-style: italic;
      color: $lightNavy;
      line-height: 0.8;
    }
    .titleDesc {
      text-align: center;
      font-style: italic;
      color: lighten($darkNavy, 10%);
      font-weight: bold;
      line-height: 1.2;
      margin-left: 10px;
      margin-right: 10px;
    }
    .itemsBox {
      // margin-top: 10px;
      margin-top: 15px;
      padding: 20px 100px;
      @media (max-width: 1023px) {
        padding: 40px;
      }
      @media (max-width: 767px) {
        padding: 10px;
      }
      @media (max-width: 359px) {
        padding: 6px;
      }
      .itemBoxGrid {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;

        @media (max-width: 767px) {
          grid-gap: 10px;
          grid-template-columns: 1fr;
        }
        .itemBox {
          display: flex;
          justify-content: center;
          &:hover {
            .imageBox {
              a {
                img {
                  border: 4px solid $veryLightOrange;
                }
              }
            }
          }
          .imageBox {
            overflow: hidden;
            width: 160px;
            height: 160px;
            backface-visibility: hidden;
            position: relative;
            .popup-box {
              display: flex;
              justify-content: center;
              align-items: center;
              position: fixed;
              // background: rgba(0, 0, 0, 0.93);
              background: rgba(0, 0, 0, 0.8);
              z-index: 200;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              right: 0;
              transition: transform 1000ms;
              .contentBox {
                border-radius: 40px;
                // background: rgba(0, 0, 0, 1);
                background: #fff;
                width: 100%;
                max-width: 820px;
                margin-left: 0;
                // margin-bottom: 10px;
                padding: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                @media (max-width: 900px) and (orientation: landscape) {
                  max-width: 390px;
                  // max-width: 668px;
                  border-radius: 0;
                  margin-left: 5px;
                  margin-right: 5px;
                }
                @media (max-width: 666px) and (orientation: landscape) {
                  max-width: 280px;
                }
                @media (max-width: 900px) and (orientation: portrait) {
                  border-radius: 0;
                }
                .title {
                  // color: $offWhite;
                  color: $black;
                  line-height: 1;
                  margin-bottom: 10px;
                  @media (max-width: 900px) and (orientation: landscape) {
                    font-size: 34px;
                    margin-bottom: 5px;
                  }
                }
                .titleDesc {
                  color: $lightNavy;
                  line-height: 1;
                  font-size: 12px;
                  @media (orientation: landscape) {
                    margin-top: 10px;
                  }
                }
                .img-box {
                  backface-visibility: hidden;
                  overflow: hidden;
                  img {
                    backface-visibility: hidden;
                    width: 100%;
                    height: auto;
                    border-radius: 40px;
                    max-width: 800px;
                    @media (max-width: 900px) and (orientation: landscape) {
                      max-width: 370px;
                    }
                    @media (max-width: 666px) and (orientation: landscape) {
                      max-width: 260px;
                    }
                  }
                }
                .desc {
                  // color: #fff;
                  color: $black;
                  text-align: center;
                  font-size: 12px;
                  @media (max-width: 900px) and (orientation: landscape) {
                    margin-top: -10px;
                  }
                }
                .priceBox {
                  line-height: 1;
                  .priceDollar,
                  .price {
                    font-size: 16px;
                    color: $red;
                  }
                  .priceDollar {
                    font-family: $fontRobotoSlab;
                  }
                  .price {
                    font-family: $fontPlayfair;
                  }
                }
              }
              // .close {
              //   position: absolute;
              //   right: 2%;
              //   top: 2%;
              //   padding: 0;
              //   margin: 0;
              //   border: 0;
              //   outline: 0;
              //   cursor: pointer;
              //   font-size: 80px;
              //   color: $offWhite;
              // }
              .close {
                position: absolute;
                right: 2%;
                top: 2%;
                padding: 0;
                margin: 0;
                outline: 0;
                width: 60px;
                height: 60px;
                border: 2px solid $offWhite;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                font-size: 30px;
                color: $offWhite;
              }
            }
            @media (max-width: 1023px) {
              width: 140px;
              height: 140px;
              img {
                border-radius: 70px;
              }
            }
            img {
              border: 4px solid rgba(0, 0, 0, 0);
              transform: translateZ(0);
              perspective: 1000;
              backface-visibility: hidden;
              border-radius: 80px;
              width: 100%;
              height: 100%;
              user-select: none;
              filter: brightness(110%);
              transition: border 200ms ease-in-out;
            }
          }
          .contentBox {
            flex: 1;
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media (max-width: 767px) {
              margin-left: 10px;
            }
            .name {
              position: relative;
              font-family: $fontRobotoSlab;
              color: $black;
              font-weight: bold;
              font-size: 18px;
              line-height: 1.2;
              margin-bottom: 5px;

              .nameDescription {
                position: absolute;
                font-size: 10px;
                top: 40%;
                margin-left: 4px;
                color: $lightNavy;
                @media (max-width: 359px) {
                  font-size: 9px;
                  margin-left: 2px;
                  top: 40%;
                  line-height: 1;
                }
              }
              .day {
                position: absolute;
                font-size: 12px;
                font-style: italic;
                // margin-top: -12px;
                margin-top: -18px;

                top: 0;
                left: 10%;
                padding: 1px 6px;
                border-radius: 9px;

                background: $green;
                color: $offWhite;
              }
            }
            .price {
              font-size: 18px;
              font-weight: bold;
              font-family: $fontPlayfair;
              color: lighten($darkRed, 5%);

              .striked {
                font-family: $fontRobotoSlab;
                color: $darkRed;
                text-decoration: line-through;
              }

              .dollar-sign {
                font-family: $fontRobotoSlab;
                margin-right: 2px;
              }
            }
            .description {
              color: $grey;
              font-style: italic;
              line-height: 1.2;
            }
            .description2 {
              color: lighten($darkNavy, 20%);
              font-weight: bold;
              line-height: 1.2;
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }
}
