@import './var';

.landing-catering {
  margin-top: 40px;
  padding-bottom: 40px;
  // min-height: 40vh;
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.8)
    ),
    url('/images/landing-catering-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $offWhite;
  .title {
    margin-top: 40px;
    font-family: $fontKalam;
    font-size: 30px;
    line-height: 1;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.72), 0 0 14px rgba(0, 0, 0, 0.45);
    @media (min-width: 399px) {
      font-size: 40px;
    }
    @media (min-width: 1024px) {
      // font-size: 44px;
      font-size: 56px;
    }
  }
  .subTitle {
    // margin-bottom: 40px;
    text-align: center;
    line-height: 1.2;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.72), 0 0 14px rgba(0, 0, 0, 0.45);
  }
  .expand-button-box {
    margin-top: 10px;
    .button {
      padding: 5px 10px;
    }
    .expandAllButton {
      background: $green;
      margin-right: 10px;
    }
    .shirinkAllButton {
      background: $darkOrange;
    }
  }

  .contentBox {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    .itemName {
      font-size: 24px;
      padding: 5 10px;
      text-shadow: 0 0 4px rgba(0, 0, 0, 0.72), 0 0 14px rgba(0, 0, 0, 0.45);
      // font-family: $fontOswald;
      &:not(:nth-of-type(2)) {
        margin-top: 10px;
        text-align: center;
      }
    }

    .leftBox {
      padding: 20px;
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        // .itemName {
        //   margin-top: 10px;
        //   text-align: center;
        // }
      }
    }
    .middleBox {
      // padding: 20px;
      padding: 20px;
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        // .itemName {
        //   margin-top: 10px;
        //   text-align: center;
        // }
      }
    }
    .rightBox {
      padding: 20px;
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        // .itemName {
        //   margin-top: 10px;
        //   text-align: center;
        // }
      }
    }

    .panel {
      background: transparent;
      max-height: 0;
      overflow: hidden;
      transition: max-height 200ms ease-in-out;
      li {
        // margin-top: 5px;
        font-size: 16px;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.72), 0 0 14px rgba(0, 0, 0, 0.45);
      }
    }
    .titleButton {
      color: $offWhite;
      transition: color 200ms ease-in-out;
      &.active,
      &:hover {
        .itemName {
          // background: #ccc;
          color: $yellow;
        }
      }
      // &.active {
      //   .panel {
      //     max-height: 100%;
      //   }
      // }
    }
  }
  .expand-button-box {
    button {
      color: $offWhite;
    }
  }
  ul li .panel-ul {
    list-style-type: circle !important;
  }
}
