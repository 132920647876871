@import './var';

html {
  box-sizing: border-box;
  font-size: 10px;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  // outline: 0;
  // border: 0;
  // overflow: hidden;
  // backface-visibility: hidden;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: $black;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  // -webkit-overflow-scrolling: touch;
}
body {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  line-height: 2;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 1000;
}

.rose-hr-box {
  display: flex;
  justify-content: center;
  .rose-hr {
    width: auto;
    height: 20px;
  }
}

button,
button:focus,
a,
a:focus,
a:link,
a:active,
a:visited {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  outline: 0;
  border: 0;
  cursor: pointer;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

.h1-heading-center {
  margin: 0 auto;
  text-align: center;

  line-height: 1;

  color: $darkNavy;

  font-family: $fontAmatic;
  font-size: 64px;
  font-weight: 700;

  @media (max-width: 767px) {
    font-size: 44px;
  }
}
.mtb-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mtb-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.ptb-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.ptb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
