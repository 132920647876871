@import './var';

.testimonials {
  width: 100%;
  margin-bottom: 10px;
  // padding: 0 5px;
  .seoulContainer {
    padding: 20px 0;
    // background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)),
    //   url('/images/landing/testimonials-background.jpg');
    // background-size: cover;
    // background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5));
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
      url('/images/review_background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    h1 {
      font-family: 'Kalam', cursive;
      // font-family: $fontAmatic;

      font-weight: bold;
      text-align: center;
      // font-size: 46px;
      color: #fff;
      line-height: 1;
    }
    .button-box {
      display: flex;
      justify-content: center;
      // margin-top: 20px;
      button:nth-of-type(1) {
        margin-right: 2rem;
      }
      button {
        // padding: 0.5rem 2rem;
        font-size: 16px;
        // padding: 8px 30px;
        padding: 10px 30px;
        outline: none;
        border: none;
        border-radius: 5px;
        font-weight: bold;
        cursor: pointer;
        // background: #dc3522;
        // background: darken(#ffd800, 2%);
        background: $darkRed;
        color: #fff;
        transition: all 300ms ease;
      }
      button:active {
        transform: scale(0.9);
      }
      button.disabled {
        visibility: hidden;
      }
      button:disabled {
        // background: lighten(#dc3522, 30%);
        // background: lighten(#ffd800, 30%);
        background: lighten($darkRed, 60%);
        color: #ddd;
        cursor: not-allowed;
        transform: scale(1);
        visibility: hidden;
      }
    }
    .m-cards-slider {
      margin-top: 1rem;
      position: relative;

      padding: 0;

      overflow: hidden;
      margin: 0 auto;

      .m-cards-slider-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        transition: all 300ms ease-in-out;
        .t-card {
          padding: 1.5rem;
          text-align: center;
          display: flex;
          flex-direction: column;

          color: #fff;

          .feedback {
            p {
              font-family: 'Lato', sans-serif;

              line-height: 34px;
              word-spacing: 4px;
            }
            .fa-quote-left {
              font-size: 24px;
              margin-right: 0.5rem;
            }
            .fa-quote-right {
              font-size: 24px;

              margin-left: 0.5rem;
            }
          }

          h5 {
            font-size: 18px;
          }
        }
      }
    }
  }
  .more-reviews {
    background: $lightGrey;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 399px) {
    .seoulContainer {
      h1 {
        margin-top: 0.5rem;
        font-size: 28px;
      }
      .m-cards-slider {
        width: 280px !important;
        height: 320px !important;
        .t-card {
          min-width: 280px !important;
          width: 280px !important;
          padding: 0.5rem !important;

          .feedback {
            p {
              line-height: 24px !important;
            }
          }
        }
      }
      .button-box {
        margin-bottom: 0.5rem;
      }
    }
  }
  @media (min-width: 400px) {
    .seoulContainer {
      h1 {
        margin-top: 1rem;
        font-size: 30px;
      }
      .m-cards-slider {
        width: 380px !important;
        height: 300px !important;
        .t-card {
          min-width: 380px !important;
          width: 380px !important;
          padding: 1rem !important;

          .feedback {
            p {
              line-height: 30px !important;
            }
          }
        }
      }
      .button-box {
        margin-bottom: 1rem;
      }
    }
  }
  @media (min-width: 576px) {
    // margin-bottom: 0.5rem;
    .seoulContainer {
      .m-cards-slider {
        width: 400px !important;
        height: 300px !important;
        .t-card {
          min-width: 400px !important;
          width: 400px !important;
        }
      }
    }
  }
  @media (min-width: 768px) {
    // margin-top: 0.5rem;
    // margin-bottom: 1rem;
    margin-top: 20px;
    margin-bottom: 20px;
    .seoulContainer {
      h1 {
        font-size: 56px;
      }
      .m-cards-slider {
        width: 500px !important;
        height: 260px !important;
        .t-card {
          min-width: 500px !important;
          width: 500px !important;
        }
      }
    }
  }
  @media (min-width: 992px) {
    // margin-top: 1rem;
    // margin-bottom: 1.5rem;
    // margin-top: 20px;
    // margin-bottom: 20px;
    .seoulContainer {
      .m-cards-slider {
        width: 600px !important;
        height: 260px !important;
        .t-card {
          min-width: 600px !important;
          width: 600px !important;
        }
      }
    }
  }
  @media (min-width: 1460px) {
    // margin-top: 1rem;
    margin-top: 40px;
    margin-bottom: 40px;
    // margin-bottom: 1.5rem;
    .seoulContainer {
      h1 {
        margin-top: 2rem;
      }
      .m-cards-slider {
        width: 800px !important;
        height: 240px !important;
        .t-card {
          min-width: 800px !important;
          width: 800px !important;
        }
      }
      .button-box {
        margin-bottom: 2rem;
      }
    }
  }

  .menu-line-box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    img {
      height: 25px;
      width: auto;
    }
  }
}
