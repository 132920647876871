@import "./var";

.landingHeader {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)),
    url("/images/landing-header-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
  backface-visibility: hidden;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    // transition: all 1000ms;
    transition: opacity 300ms, transform 1000ms;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    backface-visibility: hidden;
    &.animate {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
    * {
      text-align: center;
    }
    h1 {
      // font-family: 'Amatic SC', cursive;
      font-family: $fontAmatic;
      font-size: 64px;
      line-height: 1;
      color: #fff;
      max-width: 840px;
    }
    h2 {
      font-family: "Amatic SC", cursive;
      font-size: 50px;
      color: #fff;
      max-width: 740px;
    }
    h4 {
      margin-top: 20px;
      font-family: "Amatic SC", cursive;
      color: #fff;
      font-size: 24px;
      line-height: 1.4;
      max-width: 640px;
    }
    a {
      margin-top: 20px;
      padding: 10px 30px;
      // background: ${props => props.theme.lightRed};
      background: $lightRed;
      color: #f2f2f2;
      // border: 1px solid ${props => props.theme.lightRed};
      border: 1px solid $lightRed;
      border-radius: 30px;
      outline: none;
      transition: background 300ms linear, color 300ms linear, opacity 500ms;
      opacity: 0;
      &:hover {
        // background: ${props => props.theme.offWhite};
        background: $offWhite;
        // color: ${props => props.theme.lightRed};
        color: $lightRed;
      }
      &.animate {
        opacity: 1;
      }
    }
    @media (max-width: 767px) {
      h1 {
        font-size: 48px;
        line-height: 1;
      }
      h4 {
        line-height: 1.2;
      }
    }
  }
}
