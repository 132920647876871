@charset "UTF-8";
.prettyHr {
  margin: 0 auto;
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
  max-width: 200px;
  padding: 0;
  border: none;
  /* border-top: medium double #888; */
  border-top: medium double #ededed;
  color: #888;
  text-align: center;
  overflow: visible;
  z-index: 0;
  position: relative; }
  .prettyHr:after {
    content: '§';
    display: inline-block;
    position: absolute;
    color: #ededed;
    top: -2.2rem;
    left: 50%;
    transform: translateX(-50%) rotate(25deg);
    font-size: 2rem;
    padding: 0 0.2rem;
    text-align: center;
    z-index: 1; }
