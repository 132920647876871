@import './var.scss';

.menuNav {
  z-index: 1;
  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;

  // background: ${props => (props.fixed ? props.theme.lightOrange : 'none')};
  transition: all 300ms;
  display: flex;
  // height: 54px;
  // align-items: center;
  height: 52px;
  &.fixed {
    position: fixed;
    // background: $lightOrange;
    background: $veryLightOrange;
  }
  * {
    overflow: hidden;
  }
  .slider-wrapper {
    z-index: 2;
    display: flex;
    justify-content: center;
    height: 48px;
    .slider-inner {
      z-index: 4;
      // background: ${props => props.theme.lightGrey};
      background: $lightGrey;
      position: relative;
      overflow-x: scroll;
      height: 70px;
      // width: 640px;
      // width: 740px;
      // width: 670px;
      // width: 640px;
      width: 680px;
      ul.slider {
        z-index: 5;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: 'flex-start';
        flex-wrap: nowrap;

        // left: ${props => (props.shouldBeCentered ? '50%' : 0)};

        // transform: ${props =>
        //   props.shouldBeCentered ? 'translateX(-50%)' : 9};
        position: absolute;
        height: 70px;
        /* top: -11px; */
        top: -11px;
        &.center {
          left: 50%;
          transform: translateX(-50%);
        }
        li {
          // margin-left: 6px;
          margin-left: 2px;
          border-radius: 20px;
          height: 40px;
          padding: 2px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          &:last-child {
            // margin-right: 6px;
            margin-right: 2px;
          }
          a {
            width: 100%;
            height: 100%;
            // font-family: 'Leckerli One', cursive;
            // font-size: 16px;
            // padding: 0 10px;
            font-family: 'Amatic SC', cursive;
            font-size: 20px;
            font-weight: 700;
            padding: 0 14px;

            height: 40px;
            display: flex;
            align-items: center;
            border-radius: inherit;
            transition: all 300ms ease-in-out;
            white-space: nowrap;
            // color: ${props => props.theme.darkRed};
            color: $darkRed;
            &:hover {
              // color: lighten($darkRed, 15%);
              color: $black;
            }
            &.menu-nav-active {
              /* background: ${props => props.theme.lightRed}; */
              // background: ${props => props.theme.darkRed};
              background: $darkRed;
              // color: ${props => props.theme.offWhite};
              color: $offWhite;
            }
          }
        }
      }
    }
  }
}
