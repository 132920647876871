@import './var';

.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  h1 {
    text-align: center;
    color: lighten($darkNavy, 10%);
  }
  a {
    background: $lightOrange;
    padding: 10px 20px;
    transform: skewX(-20deg);
    color: $darkRed;
    border-radius: 5px;
    div {
      transform: skewX(20deg);
      .fa-chevron-left {
        margin-right: 10px;
      }
    }
  }
}
