$lightRed: #dc3522;
$veryLightRed: #ff2929;
$red: #d80000;
$darkRed: #8b0000;
$prettyPink: #cf3472;
$yellow: #ffe866;
$green: #008b00;
$black: #393939;
$lightBlue: #0080ff;
$lightNavy: #6389c1;
$darkNavy: #232d3e;
$grey: #3a3a3a;
$mediumGrey: #cbcbcb;
$lightGrey: #e1e1e1;
$darkOrange: #e87c03;
$orange: #ffa500;
$lightOrange: rgb(243, 168, 29);
$veryLightOrange: #ffb733;
$offWhite: #ededed;
$maxWidth: 1000px;
$bs: 0 12px 24px 0 rgba(0, 0, 0, 0.09);
$fontAmatic: "Amatic SC", cursive;
$fontLeckerli: "Leckerli One", cursive;
$fontSeaweed: "Seaweed Script", cursive;
$fontPlayfair: "Playfair Display", serif;
$fontRobotoSlab: "Roboto Slab", serif;
$fontVesper: "Vesper Libre", serif;
$fontOpenSans: "Open Sans", sans-serif;
$fontKalam: "Kalam", cursive;
$fontOswald: "Oswald", sans-serif;

// hr <a href="https://pngtree.com/free-vectors">free vectors from pngtree.com</a>
