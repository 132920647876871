@import './var';

.mainNav {
  position: fixed;
  &.fixed {
    // background: $lightOrange;
    background: $veryLightOrange;
    a {
      color: $offWhite;
    }
  }
  left: 0;
  top: 0;
  z-index: 100;
  transition: background 300ms;
  ul {
    display: flex;
    justify-content: center;
    li {
      padding: 0.5rem 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      &:first-child {
        margin-right: 1rem;
      }
      a {
        font-size: 18px;
        text-transform: uppercase;
        // font-weight: 500;
        font-weight: bold;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: color 300ms ease-in-out;
        color: #f2f2f2;
        &.active {
          color: $darkRed;
          &:hover {
            color: $darkRed;
          }
        }
        &:hover {
          // color: lighten($darkRed, 10%);
          // color: $darkRed;
          color: $black;
        }
        div {
          display: flex;
          align-items: flex-end;
          & > *:nth-child(2) {
            // margin-left: 10px;
            margin-left: 6px;
          }
          img {
            width: auto;
            object-fit: cover;
          }
          img:nth-child(1) {
            height: 60px;
          }
          img:nth-child(2) {
            height: 38px;
          }
        }
      }
    }
  }

  &.mobileNav {
    &.fixed {
      // background: $lightOrange !important;
      background: $veryLightOrange !important;
    }
    .seoulContainer {
      position: relative;
      ul {
        justify-content: flex-start;
        li {
          a {
            // height: 50px;
            div {
              & > *:nth-child(2) {
                margin-left: 4px;
              }
              img:nth-child(1) {
                height: 50px;
              }
              img:nth-child(2) {
                height: 30px;
              }
            }
          }
        }
      }
      .mobile-nav-button {
        position: absolute;
        top: 50%;
        right: 4%;
        transform: translateY(-50%);
        background: none;
        .bar1,
        .bar2,
        .bar3 {
          width: 35px;
          height: 3px;
          background: #f2f2f2;
          margin: 6px 0;
          transition: 400ms;
        }
        &.change .bar1 {
          transform: rotate(-45deg) translate(-8px, 4px);
        }

        &.change .bar2 {
          opacity: 0;
        }

        &.change .bar3 {
          transform: rotate(45deg) translate(-8px, -5px);
        }
      }
    }
  }
}

.pop-up {
  position: fixed;
  background: $veryLightOrange;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  transform: scale(0);
  transition: transform 250ms ease, opacity 1000ms ease;
  &.show {
    transform: scale(1);
    a {
      opacity: 1;
    }
  }
  ul {
    li {
      padding: 10px 15px;

      a {
        color: #f2f2f2;
        font-size: 20px;
        text-transform: uppercase;
        opacity: 0;
        font-weight: bold;
        &.active {
          color: $darkRed;
        }
      }
    }
  }
}
