@import "./var.scss";

.master-footer {
  // margin-top: 8px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
    url("/images/contact-header.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    // margin-top: 4px;
  }

  .to-top-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    .to-top {
      padding: 10px 20px;
      // border-radius: 5px;
      bottom: 0;
      right: 0;
      color: #fff;
      cursor: pointer;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 1;
      font-family: $fontKalam;
    }
  }

  .footer-header {
    // padding: 4rem;
    display: flex;
    justify-content: center;
    // align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 0;
      padding-bottom: 20px;
    }
    .footer-item {
      width: 33.33%;
      // margin-left: 2rem;
      margin-top: 30px;
      margin-bottom: 30px;
      color: #fff;
      padding-left: 20px;
      padding-right: 20px;
      h1 {
        font-family: $fontVesper;
        text-align: center;
      }
      @media (max-width: 768px) {
        width: 80%;
        margin: 0 auto;
        margin-top: 30px;
        // &:nth-child(1) {
        //   margin-top: 40px;
        // }
        padding-left: 20px;
        padding-right: 20px;
      }
      @media (max-width: 576px) {
        width: 100%;
        margin: 0 auto;
        margin-top: 30px;
        // margin-top: 1rem;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .about {
      display: flex;
      flex-direction: column;
      align-items: center;
      .heading {
        display: flex;
        align-items: center;
        h1 {
          color: $lightGrey;
          vertical-align: top;

          line-height: 1;
        }
        img {
          margin-left: 16px;
          width: auto;
          height: 60px;
        }
      }
      p {
        width: 90%;
        margin-top: 10px;
        color: #fff;
        text-align: center;
        span {
          font-style: italic;
          font-weight: bold;
        }
      }
      a {
        margin-top: 8px;
        font-family: $fontAmatic;
        font-size: 24px;
        font-weight: bold;
        border: 1px solid $darkRed;
        border-radius: 5px;
        padding: 2px 16px;
        background: $darkRed;
        color: white;
        transition: all 350ms ease-in-out;
        &:hover {
          background: white;
          color: $darkRed;
        }
      }
    }

    .hours {
      @media (max-width: 768px) {
        &.hide {
          display: none;
        }
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      hr {
        background: $lightGrey;
        height: 2px;
        width: 100%;
        max-width: 160px;
        margin: 0 auto;
      }
      .time {
        margin: 0 auto;
        margin-top: 16px;
        width: 80%;
        @media (max-width: 1460px) {
          width: 100%;
        }
        @media (max-width: 767px) {
          width: 90%;
        }
        @media (max-width: 359px) {
          width: 98%;
        }
        div {
          width: 100%;
          display: flex;

          span:nth-child(1) {
            text-align: left;
            width: 50%;
          }
          span:nth-child(2) {
            font-family: $fontPlayfair;
            // color: darken(lightgreen, 25%);
            color: lighten($green, 10%);
            text-align: right;
            width: 50%;
            font-weight: bold;
          }
          &.closed {
            span:nth-child(2) {
              font-family: $fontPlayfair;
              // color: lighten($red, 10%);
              // color: $darkRed;
              color: lighten($darkRed, 10%);
              font-weight: bold;
            }
          }
        }
      }
    }

    .info {
      @media (max-width: 768px) {
        &.hide {
          display: none;
        }
      }
      margin-bottom: 10px;
      .info-items {
        width: 80%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        .info-item {
          display: flex;
          line-height: 1;
          margin-bottom: 20px;
          .fa-map-marked-alt,
          .fa-phone {
            color: $orange;
            margin-right: 20px;
          }
        }
        .amazingMenu,
        .moreInfo {
          // margin-top: 8px;
          margin: 0 auto;
          font-family: $fontAmatic;
          font-size: 24px;
          font-weight: bold;
          // border: 1px solid $lightBlue;
          // background: $lightBlue;
          border: 1px solid $darkNavy;
          background: $darkNavy;
          border-radius: 5px;
          padding: 2px 16px;
          color: white;
          transition: all 350ms ease-in-out;
          &:hover {
            // background: white;
            // color: $lightBlue;
            background: white;
            color: $darkNavy;
          }
        }
      }
    }
  }
  .copy-right {
    height: 60px;
    // height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $offWhite;
    color: $black;
    p {
      text-align: center;
      line-height: 1.3;
    }
    .designed-by {
      margin-left: 10px;
      .family-of-light {
        font-family: $fontPlayfair;
        padding: 8px 4px;
        border-radius: 2px;
        &:hover {
          background: $darkNavy;
          color: white;
          transition: all 250ms linear;
          a {
            background: transparent;
            color: inherit;
          }
        }
      }
    }
  }
}

// @import './var.scss';

// .master-footer {
//   margin-top: 8px;
//   background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
//     url('/images/contact-header.jpg');
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center center;
//   width: 100%;
//   position: relative;
//   display: flex;
//   flex-direction: column;

//   .footer-header {
//     padding: 4rem;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     @media (max-width: 768px) {
//       flex-direction: column;
//       padding: 0;
//     }
//     .footer-item {
//       width: 25%;
//       margin-left: 2rem;
//       color: #fff;

//       h1 {
//         font-family: $fontVesper;
//         text-align: center;
//       }
//       @media (max-width: 768px) {
//         width: 80%;
//         margin: 0 auto;
//         margin-top: 40px;
//         // &:nth-child(1) {
//         //   margin-top: 40px;
//         // }
//         padding-left: 20px;
//         padding-right: 20px;
//       }
//       @media (max-width: 576px) {
//         width: 100%;
//         margin: 0 auto;
//         margin-top: 30px;
//         // margin-top: 1rem;
//         padding-left: 10px;
//         padding-right: 10px;
//       }
//     }

//     .about {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       .heading {
//         display: flex;
//         align-items: center;
//         h1 {
//           color: $lightGrey;
//           vertical-align: top;

//           line-height: 1;
//         }
//         img {
//           margin-left: 16px;
//           width: auto;
//           height: 60px;
//         }
//       }
//       p {
//         width: 100%;
//         margin-top: 10px;
//         color: #fff;
//         text-align: center;
//         span {
//           font-style: italic;
//           font-weight: bold;
//         }
//       }
//     }

//     .hours {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       hr {
//         background: $lightGrey;
//         height: 2px;
//         width: 100%;
//         max-width: 160px;
//         margin: 0 auto;
//       }
//       .time {
//         margin: 0 auto;
//         margin-top: 16px;
//         width: 62%;
//         @media (max-width: 767px) {
//           width: 90%;
//         }
//         @media (max-width: 359px) {
//           width: 98%;
//         }
//         div {
//           width: 100%;
//           display: flex;

//           span:nth-child(1) {
//             text-align: left;
//             width: 50%;
//           }
//           span:nth-child(2) {
//             color: lightgreen;
//             text-align: center;
//             width: 50%;
//             font-weight: bold;
//           }
//           &.closed {
//             span:nth-child(2) {
//               color: $red;
//               font-weight: bold;
//             }
//           }
//         }
//       }
//     }
//   }
//   .copy-right {
//     height: 60px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background: $offWhite;
//     color: $black;
//     p {
//       text-align: center;
//       line-height: 1.3;
//     }
//   }
// }
