.loading-spin {
  animation: App-logo-spin infinite 1s linear;
  z-index: 1001;
  font-size: 32px;
  // color: #cf3472;
  color: #333;
  position: fixed;
  top: 1%;
  left: 92%;
  @media (max-width: 767px) {
    left: 70%;
    transform: translateX(-70%);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
